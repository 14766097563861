import * as React from 'react'

import { RenderAnnouncerType } from '@thg-commerce/gravity-elements'
import { Search } from '@thg-commerce/gravity-icons/src'
import { IconStyling } from '@thg-commerce/gravity-patterns/Header/theme'
import { styled } from '@thg-commerce/gravity-patterns/theme'
import { useScrollLock } from '@thg-commerce/gravity-system'
import { mq, spacing } from '@thg-commerce/gravity-theme'

import { HeaderDropdown } from '../HeaderDropdown'
import {
  HeaderSearchForm,
  InstantSearchProps,
  SearchI18nText,
  TrendingTerms,
} from '../HeaderSearchForm'
import { DropdownOptions, PromotionalProducts } from '../types'

export interface HeaderSearchProps {
  currency: string
  shippingDestination: string
  isMobile: boolean
  headerSearchI18nText: HeaderSearchI18nText
  InstantSearchInjector: (props: {
    children: (props: InstantSearchProps) => React.ReactNode
  }) => JSX.Element
  searchI18nText: SearchI18nText
  onSubmit: (searchText: string) => void
  autocompleteLink: string
  renderAnnouncer: RenderAnnouncerType
  triggerAriaLabels: { close: string; search: string }
  mobileIcon?: IconStyling
  concessionCode?: string
  onFocus?: (event: React.FocusEvent) => void
  mobileSearch: boolean
  slimHeader: boolean
  showExposedSearch: boolean
  vipPriceEnabled?: boolean
  showPowerReview: boolean
  enableRecentSearches?: boolean
  recentlySearchedTitle?: string
  recentlySearchedText?: string
  clearRecentSearch?: string
  enablePromotionalSearch?: boolean
  promotionalProducts?: PromotionalProducts
  trendingTerms?: TrendingTerms
  enableTrendingSearch?: boolean
  displaySocialEngagement?: boolean
  enablePersistentSearch?: boolean
}

interface HeaderSearchI18nText {
  placeholder: string
  searchButtonAriaLabel: string
  clearAriaLabel: string
}

const SearchDropdownWrapper = styled.div<{ removePadding: boolean }>`
  ${(props) =>
    props.removePadding
      ? `
      border-bottom: ${props.theme.patterns.header.navigation.borderBottom};

      form {
        border: none;
      }`
      : `padding: ${spacing(2)};
  background-color: ${props.theme.colors.palette.greys.lighter};`}
`

const SearchDesktopWrapper = styled.div<{ mobileVisible: boolean }>`
  visibility: ${(props) => (props.mobileVisible ? 'visible' : 'hidden')};

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    visibility: visible;
  }
`

const SearchIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;

  svg {
    fill: ${(props) => props.theme.patterns.header.search.mobileIcon.color};
  }
`

const SearchComponent = (
  props: HeaderSearchProps & { close?: () => void; focusOnMount?: boolean },
) => {
  const setScrollLock = useScrollLock()
  return (
    <SearchDropdownWrapper removePadding={props.showExposedSearch}>
      <HeaderSearchForm
        setScrollLock={setScrollLock}
        placeholder={props.headerSearchI18nText.placeholder}
        searchButtonAriaLabel={props.headerSearchI18nText.searchButtonAriaLabel}
        clearAriaLabel={props.headerSearchI18nText.clearAriaLabel}
        isDesktop={!props.isMobile}
        InstantSearchInjector={props.InstantSearchInjector}
        searchI18nText={props.searchI18nText}
        onSubmit={props.onSubmit}
        autocompleteLink={props.autocompleteLink}
        renderAnnouncer={props.renderAnnouncer}
        currency={props.currency}
        shippingDestination={props.shippingDestination}
        externalClose={props.close}
        icon={props.mobileIcon}
        concessionCode={props.concessionCode}
        onFocus={props.onFocus}
        mobileSearch={props.mobileSearch}
        slimHeader={props.slimHeader}
        focusOnMount={props.focusOnMount}
        vipPriceEnabled={props?.vipPriceEnabled}
        showPowerReview={props.showPowerReview}
        {...(props.enableRecentSearches && {
          enableRecentSearches: props.enableRecentSearches,
          recentlySearchedTitle: props.recentlySearchedTitle,
          recentlySearchedText: props.recentlySearchedText,
          clearRecentSearch: props.clearRecentSearch,
        })}
        {...(props.promotionalProducts && {
          promotionalProducts: props.promotionalProducts,
        })}
        enablePromotionalSearch={props.enablePromotionalSearch}
        trendingTerms={props.trendingTerms}
        enableTrendingSearch={props.enableTrendingSearch}
        displaySocialEngagement={props.displaySocialEngagement}
      />
    </SearchDropdownWrapper>
  )
}

export const HeaderSearch = (props: HeaderSearchProps) => {
  const [isShowing, setIsShowing] = React.useState<boolean>(false)
  const [displaySocialEngagement, setDisplaySocialEngagement] = React.useState<
    boolean
  >(false)
  const setScrollLock = useScrollLock()
  React.useEffect(() => {
    isShowing && setIsShowing(false)
  }, [props.isMobile, isShowing])

  if (props.isMobile || props.mobileSearch) {
    return (
      <HeaderDropdown
        dropdownType={DropdownOptions.SEARCH}
        tabIndex={props.isMobile ? 3 : props.mobileSearch ? undefined : 0}
        i18nAriaLabels={{
          close: props.triggerAriaLabels.close,
          trigger: props.triggerAriaLabels.search,
        }}
        isMobile={props.isMobile || props.mobileSearch}
        isDesktop={!props.isMobile}
        dropdownWidth={props.mobileSearch ? '470px' : undefined}
        trigger={{
          icon: (
            <SearchIconWrapper data-testid="search-icon-wrapper">
              {props.mobileIcon?.svgPath !== '' ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox={props.mobileIcon?.viewBox}
                  height={props.mobileIcon?.height}
                  width={props.mobileIcon?.width}
                >
                  <path
                    d={props.mobileIcon?.svgPath}
                    fill-rule="evenodd"
                  ></path>
                </svg>
              ) : (
                <Search />
              )}
            </SearchIconWrapper>
          ),
          text: '',
          triggerHref: '',
        }}
        disableFocusLogic={true}
        disableAnimation={true}
        content={(close?: () => void) => (
          <SearchComponent
            close={close}
            focusOnMount={false}
            displaySocialEngagement={displaySocialEngagement}
            {...props}
          />
        )}
        mobileSearch={props.mobileSearch}
        setDisplaySocialEngagement={setDisplaySocialEngagement}
      />
    )
  }
  return (
    <SearchDesktopWrapper mobileVisible={props.showExposedSearch}>
      <HeaderSearchForm
        setScrollLock={setScrollLock}
        placeholder={props.headerSearchI18nText.placeholder}
        searchButtonAriaLabel={props.headerSearchI18nText.searchButtonAriaLabel}
        clearAriaLabel={props.headerSearchI18nText.clearAriaLabel}
        isDesktop={!props.isMobile || !props.mobileSearch}
        InstantSearchInjector={props.InstantSearchInjector}
        searchI18nText={props.searchI18nText}
        onSubmit={props.onSubmit}
        autocompleteLink={props.autocompleteLink}
        renderAnnouncer={props.renderAnnouncer}
        currency={props.currency}
        shippingDestination={props.shippingDestination}
        icon={props.mobileIcon}
        onFocus={props.onFocus}
        mobileSearch={props.mobileSearch}
        slimHeader={props.slimHeader}
        vipPriceEnabled={props.vipPriceEnabled}
        showPowerReview={props.showPowerReview}
        {...(props.enableRecentSearches && {
          enableRecentSearches: props.enableRecentSearches,
          recentlySearchedTitle: props.recentlySearchedTitle,
          recentlySearchedText: props.recentlySearchedText,
          clearRecentSearch: props.clearRecentSearch,
        })}
        promotionalProducts={props.promotionalProducts}
        enablePromotionalSearch={props.enablePromotionalSearch}
        trendingTerms={props.trendingTerms}
        enableTrendingSearch={props.enableTrendingSearch}
        displaySocialEngagement={displaySocialEngagement}
        enablePersistentSearch={props.enablePersistentSearch}
      />
    </SearchDesktopWrapper>
  )
}

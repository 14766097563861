import * as React from 'react'
import loadable from '@loadable/component'
import * as Cookies from 'js-cookie'

import { CustomAnimatePresence } from '@thg-commerce/gravity-animations'
import { RenderAnnouncerType } from '@thg-commerce/gravity-elements'
import { IconDelete, Search } from '@thg-commerce/gravity-icons/src'
import { IconStyling } from '@thg-commerce/gravity-patterns/Header/theme'
import { isIOSSafari } from '@thg-commerce/gravity-system'

import { SearchResults } from '../HeaderSearch/SearchResultsDropdown'
import { RecentSearches } from '../HeaderSearch/SearchResultsDropdown/RecentSearches/RecentSearches'
import { DropdownWrapper } from '../HeaderSearch/SearchResultsDropdown/SearchResultsDropdown'
import { PromotionalProducts } from '../types'

import {
  ClearInput,
  DropdownOverlay,
  SearchButton,
  SearchContainer,
  SearchForm,
  SearchInput,
  SearchInputLabel,
  SearchWrapper,
  StyledSearchDropdown,
  StyledSearchResultsDropdown,
} from './styles'
import {
  InstantSearchInputProps,
  InstantSearchProps,
  SearchI18nText,
  TrendingTerms,
} from './types'
import {
  onKeyDown,
  onKeyDownEvent,
  removeIndividualSearch,
  removeRecentlySearched,
  updateRecentlySearched,
} from './utils'

const SearchComponentsDropdown = loadable(
  () =>
    import('../HeaderSearch/SearchComponentsDropdown').then(
      (mod) => mod.SearchComponentsDropdown,
    ),
  { ssr: true },
)

interface RecentlySearched {
  itemName: string
  timeStamp: Date
}

const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = React.useState(value)

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)
    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

const InstantSearchInput = (props: InstantSearchInputProps) => {
  const {
    execSearchQuery,
    placeholder,
    data,
    setValue,
    setInstantSearchResults,
    inputValue,
    inputRef,
    restartIndexes,
    setAnnounceMessage,
    resultsAvailable,
    onKeyDown,
    debounceDelay,
    minSearchLength,
    onFocusDropdown,
    currency,
    shippingDestination,
    concessionCode,
    vipPriceEnabled,
    enablePersistentSearch,
  } = props

  const DEFAULT_DEBOUNCE_DELAY = 25
  const DEFAULT_MIN_SEARCH_LENGTH = 1

  const debounce: number = debounceDelay || DEFAULT_DEBOUNCE_DELAY
  const minSearch: number = minSearchLength || DEFAULT_MIN_SEARCH_LENGTH
  const debouncedSearchTerm = useDebounce(inputValue, debounce)
  const DEFAULT_CURRENCY = 'GBP'
  const DEFAULT_SHIPPING_DESTINATION = 'GB'
  const QUERY_PAGINATION_LENGTH = 5

  React.useEffect(() => {
    if (!execSearchQuery) {
      return
    }
    const usedTerm =
      debouncedSearchTerm.length >= minSearch ? debouncedSearchTerm : ''
    execSearchQuery({
      variables: {
        concessionCode,
        vipPriceEnabled,
        currency: currency || DEFAULT_CURRENCY,
        shippingDestination:
          shippingDestination || DEFAULT_SHIPPING_DESTINATION,
        query: debouncedSearchTerm,
        limit: QUERY_PAGINATION_LENGTH,
      },
    })
    if (!usedTerm) {
      setInstantSearchResults(null)
      restartIndexes()
    }
  }, [
    debouncedSearchTerm,
    execSearchQuery,
    concessionCode,
    vipPriceEnabled,
    currency,
    shippingDestination,
    minSearch,
    setInstantSearchResults,
    restartIndexes,
  ])

  React.useEffect(() => {
    if (!execSearchQuery) {
      return
    }
    if (data?.instantSearch && debouncedSearchTerm.length >= minSearch) {
      setInstantSearchResults(data.instantSearch)
      setAnnounceMessage(resultsAvailable)
    }
  }, [
    data,
    execSearchQuery,
    debouncedSearchTerm,
    minSearch,
    setInstantSearchResults,
    setAnnounceMessage,
    resultsAvailable,
  ])

  React.useEffect(() => {
    const valueBySessionStorage = sessionStorage.getItem(
      'persistentSearchValue',
    )
    setValue(valueBySessionStorage || inputValue)
  }, [inputValue, setValue])

  if (!execSearchQuery) {
    return null
  }

  return (
    <SearchInput
      type="text"
      aria-label={placeholder}
      placeholder={placeholder}
      value={inputValue}
      ref={inputRef}
      data-testid={'header-search-form-input'}
      title={placeholder}
      onKeyDown={onKeyDown}
      onFocus={onFocusDropdown}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value)
        enablePersistentSearch &&
          sessionStorage.setItem('persistentSearchValue', e.target.value)
      }}
    />
  )
}

const showSearchComponentsDropdown = (
  enableTrendingSearch,
  enablePromotionalSearch,
  enableRecentSearches,
  inputFocus,
  inputValue,
  displaySocialEngagement,
) => {
  return (
    (enableTrendingSearch || enablePromotionalSearch || enableRecentSearches) &&
    (inputFocus || displaySocialEngagement) &&
    (!inputValue || inputValue?.length <= 2)
  )
}

export interface HeaderSearchFormProps {
  currency: string
  shippingDestination: string
  placeholder: string
  searchButtonAriaLabel: string
  clearAriaLabel: string
  isDesktop: boolean
  InstantSearchInjector: (props: {
    children: (props: InstantSearchProps) => React.ReactNode
  }) => JSX.Element
  searchI18nText: SearchI18nText
  onSubmit: (searchText: string) => void
  autocompleteLink: string
  renderAnnouncer: RenderAnnouncerType
  setScrollLock: (lock: boolean, $document: Document, $window: Window) => void
  externalClose?: () => void
  icon?: IconStyling
  concessionCode?: string
  onFocus?: (event: React.FocusEvent) => void
  mobileSearch: boolean
  slimHeader: boolean
  focusOnMount?: boolean
  vipPriceEnabled?: boolean
  showPowerReview: boolean
  recentlySearched?: RecentlySearched[]
  enableRecentSearches?: boolean
  recentlySearchedTitle?: string
  recentlySearchedText?: string
  clearRecentSearch?: string
  onClickSearchSuggestions?: (value: string) => void
  enablePromotionalSearch?: boolean
  enableTrendingSearch?: boolean
  promotionalProducts?: PromotionalProducts
  trendingTerms?: TrendingTerms
  onClickTrendingSearch?: (value: string) => void
  displaySocialEngagement?: boolean
  onClickSearchComponent?: (value: string) => void
  enablePersistentSearch?: boolean
}

export const HeaderSearchForm = (props: HeaderSearchFormProps) => {
  const {
    placeholder,
    searchButtonAriaLabel,
    clearAriaLabel,
    setScrollLock,
  } = props
  const [inputValue, setValue] = React.useState<string>('')
  const [
    instantSearchResults,
    setInstantSearchResults,
  ] = React.useState<SearchResults | null>(null)
  const [correctionsIndex, setCorrectionsIndex] = React.useState<number>(-1)
  const [suggestionsIndex, setSuggestionsIndex] = React.useState<number>(-1)
  const [productsIndex, setProductsIndex] = React.useState<number>(-1)
  const [inputFocused, setInputFocused] = React.useState<boolean>(false)
  const [recentSearched, setRecentSearches] = React.useState<
    RecentlySearched[]
  >([])

  const inputRef: React.RefObject<HTMLInputElement> = React.useRef<
    HTMLInputElement
  >(null)
  const contentRef = React.createRef<HTMLDivElement>()
  const [announceMessage, setAnnounceMessage] = React.useState<string | null>(
    null,
  )

  React.useEffect(() => {
    const values = Cookies.get('recentlySearchedProducts')
    if (values && values.length > 0) {
      setRecentSearches(JSON.parse(values))
    }
  }, [])

  React.useEffect(() => {
    setAnnounceMessage(null)
    if (correctionsIndex !== -1 && instantSearchResults?.corrections) {
      setAnnounceMessage(
        `${props.searchI18nText.correctionsTitle}: ${instantSearchResults?.corrections[correctionsIndex]?.correction}`,
      )
    }

    if (
      suggestionsIndex !== -1 &&
      instantSearchResults?.suggestedSearchQueries
    ) {
      setAnnounceMessage(
        `${props.searchI18nText.suggestionsTitle}: ${instantSearchResults?.suggestedSearchQueries[suggestionsIndex]}`,
      )
    }

    if (productsIndex !== -1 && instantSearchResults?.products) {
      const lastItem =
        instantSearchResults?.products.length - 1 === productsIndex
      const selectedProduct = instantSearchResults?.products[productsIndex]
      setAnnounceMessage(
        lastItem
          ? `${props.searchI18nText.lastResult}, ${props.searchI18nText.productsTitle}: ${selectedProduct.title}`
          : `${props.searchI18nText.productsTitle}: ${selectedProduct.title}`,
      )
    }
  }, [
    correctionsIndex,
    suggestionsIndex,
    productsIndex,
    instantSearchResults?.corrections,
    instantSearchResults?.suggestedSearchQueries,
    instantSearchResults?.products,
    props.searchI18nText.correctionsTitle,
    props.searchI18nText.lastResult,
    props.searchI18nText.productsTitle,
    props.searchI18nText.suggestionsTitle,
  ])

  const itemsUnselected =
    correctionsIndex === -1 && suggestionsIndex === -1 && productsIndex === -1

  const restartIndexes = () => {
    setCorrectionsIndex(-1)
    setSuggestionsIndex(-1)
    setProductsIndex(-1)
  }

  React.useEffect(() => {
    if (inputValue !== '') {
      props.isDesktop &&
        typeof document !== 'undefined' &&
        typeof window !== 'undefined' &&
        inputFocused &&
        setScrollLock(true, document, window)
    }
  }, [inputValue, props.isDesktop, setScrollLock, inputFocused])

  const clearInput = () => {
    setValue('')
    props.isDesktop &&
      typeof document !== 'undefined' &&
      typeof window !== 'undefined' &&
      setScrollLock(false, document, window)
    setInstantSearchResults(null)
    restartIndexes()
  }

  const onFocusDropdown = (event: React.FocusEvent) => {
    typeof document !== 'undefined' &&
      typeof window !== 'undefined' &&
      setScrollLock(true, document, window)
    if (inputRef.current?.contains(event.currentTarget as Node)) {
      setInputFocused(true)
      props.onFocus && props.onFocus(event)
    }
  }

  React.useEffect(() => {
    if (props.focusOnMount) {
      inputRef.current?.focus()
    }
  }, [props.focusOnMount])

  const clickAway = () => {
    clearInput()
    setInputFocused(false)
  }

  const goToLink = () => {
    if (correctionsIndex !== -1 && instantSearchResults?.corrections) {
      props.enableRecentSearches &&
        updateRecentlySearched(
          instantSearchResults?.corrections[correctionsIndex]?.correction,
        )
      window.location.assign(
        `${props.autocompleteLink}correction&search=${instantSearchResults?.corrections[correctionsIndex]?.correction}`,
      )
    }
    if (
      suggestionsIndex !== -1 &&
      instantSearchResults?.suggestedSearchQueries
    ) {
      props.enableRecentSearches &&
        updateRecentlySearched(
          instantSearchResults?.suggestedSearchQueries[suggestionsIndex],
        )
      window.location.assign(
        `${props.autocompleteLink}searchsuggestion&search=${instantSearchResults?.suggestedSearchQueries[suggestionsIndex]}`,
      )
    }
    if (productsIndex !== -1 && instantSearchResults?.products) {
      window.location.assign(instantSearchResults?.products[productsIndex]?.url)
    }
  }

  const { InstantSearchInjector } = props

  return (
    <SearchContainer ref={contentRef} mobileSearch={props.mobileSearch}>
      <SearchForm
        onSubmit={(e) => {
          e.preventDefault()
          props.onSubmit(inputValue)
          props.enableRecentSearches && updateRecentlySearched(inputValue)
        }}
        inputFocused={!props.isDesktop && inputFocused}
        mobileSearch={props.mobileSearch}
      >
        <SearchWrapper
          inputFocused={props.isDesktop && inputFocused}
          value={inputValue}
        >
          <SearchInputLabel>
            <InstantSearchInjector>
              {(instantSearchProps) => {
                return (
                  <InstantSearchInput
                    placeholder={placeholder}
                    setValue={setValue}
                    setInstantSearchResults={setInstantSearchResults}
                    inputValue={inputValue}
                    inputRef={inputRef}
                    restartIndexes={restartIndexes}
                    setAnnounceMessage={setAnnounceMessage}
                    resultsAvailable={props.searchI18nText.resultsAvailable}
                    onKeyDown={(e) =>
                      onKeyDown(
                        e,
                        instantSearchResults,
                        clearInput,
                        props.isDesktop,
                        setScrollLock,
                        restartIndexes,
                        setInputFocused,
                        itemsUnselected,
                        correctionsIndex,
                        setCorrectionsIndex,
                        productsIndex,
                        setSuggestionsIndex,
                        setProductsIndex,
                        suggestionsIndex,
                        goToLink,
                      )
                    }
                    data={instantSearchProps.data}
                    execSearchQuery={instantSearchProps.execSearchQuery}
                    onFocusDropdown={onFocusDropdown}
                    currency={props.currency}
                    concessionCode={props.concessionCode}
                    shippingDestination={props.shippingDestination}
                    vipPriceEnabled={props?.vipPriceEnabled}
                    enablePersistentSearch={props.enablePersistentSearch}
                  />
                )
              }}
            </InstantSearchInjector>
          </SearchInputLabel>
          {inputValue && (
            <ClearInput
              data-testid="clear-button"
              type="button"
              onKeyPress={(e: React.KeyboardEvent) => {
                if (e.key === 'Enter') {
                  clearInput()
                  inputRef.current?.focus()
                  props.enablePersistentSearch &&
                    sessionStorage.removeItem('persistentSearchValue')
                }
              }}
              onClick={() => {
                clearInput()
                inputRef.current?.focus()
                props.enablePersistentSearch &&
                  sessionStorage.removeItem('persistentSearchValue')
              }}
              aria-label={clearAriaLabel}
              title={clearAriaLabel}
            >
              <IconDelete data-testid="input-delete-icon" />
            </ClearInput>
          )}
        </SearchWrapper>
        <SearchButton
          type="submit"
          aria-label={searchButtonAriaLabel}
          active={Boolean(inputValue)}
          data-testid={'header-search-form-submit-button'}
          inputFocused={props.isDesktop && inputFocused}
          onKeyDown={(event: React.KeyboardEvent) =>
            onKeyDownEvent(
              event,
              inputValue,
              clearInput,
              setInputFocused,
              props.externalClose,
              props.enableRecentSearches,
              props.enablePromotionalSearch,
              props.trendingTerms,
            )
          }
        >
          {props.icon?.svgPath !== '' ? (
            <svg
              width={props.icon?.width}
              height={props.icon?.height}
              viewBox={props.icon?.viewBox}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d={props.icon?.svgPath} fill-rule="evenodd"></path>
            </svg>
          ) : (
            <Search />
          )}
        </SearchButton>
      </SearchForm>

      <SearchDropdown
        {...props}
        instantSearchResults={instantSearchResults}
        movePastInput={() => {
          setValue('')
          props.isDesktop && setScrollLock(false, document, window)
          setInstantSearchResults(null)
          restartIndexes()
          setInputFocused(false)
          props.externalClose && props.externalClose()
        }}
        inputValue={inputValue}
        correctionsIndex={correctionsIndex}
        productsIndex={productsIndex}
        suggestionsIndex={suggestionsIndex}
        clearInput={clearInput}
        inputFocused={inputFocused}
        clickAway={clickAway}
        {...(props.enableRecentSearches && {
          setRecentSearches,
          enableRecentSearches: props.enableRecentSearches,
          recentlySearched: recentSearched,
          recentlySearchedTitle: props.recentlySearchedTitle,
          recentlySearchedText: props.recentlySearchedText,
          onClick: removeRecentlySearched,
          clearRecentSearch: props.clearRecentSearch,
          onClickSearchSuggestions: (value: string) =>
            updateRecentlySearched(value),
        })}
        promotionalProducts={props.promotionalProducts}
        enablePromotionalSearch={props.enablePromotionalSearch}
        trendingTerms={props.trendingTerms}
        enableTrendingSearch={props.enableTrendingSearch}
        onClickSearchComponent={(value) => {
          if (props.enableRecentSearches) {
            updateRecentlySearched(value)
          }
        }}
        displaySocialEngagement={props.displaySocialEngagement}
      />

      {announceMessage && props.renderAnnouncer('assertive', announceMessage)}
    </SearchContainer>
  )
}

const FocusedDropdownWithNoInput = (props: {
  inputFocused: boolean
  inputValue: string
  isDesktop: boolean
  slimHeader: boolean
  movePastInput: () => void
  recentlySearched?: RecentlySearched[]
  recentlySearchedTitle?: string
  recentlySearchedText?: string
  clearRecentSearch?: string
  enablePromotionalSearch?: boolean
  trendingTerms?: TrendingTerms
  setRecentSearches?: (string) => void
  promotionalProducts?: PromotionalProducts
  onClickSearchComponent?: (value: string) => void
  enableRecentSearches?: boolean
  enableTrendingSearch?: boolean
  displaySocialEngagement?: boolean
}) => {
  const clientWidth =
    typeof document !== 'undefined' && document
      ? document.documentElement.clientWidth
      : 0
  return (
    <StyledSearchDropdown>
      <DropdownWrapper
        pageWidth={clientWidth}
        cutBottom={isIOSSafari}
        slimHeader={props.slimHeader}
        style={{ overflow: 'auto' }}
        paddingTop={props.isDesktop ? 0 : 2}
      >
        {props.enableRecentSearches &&
          props.recentlySearched &&
          props.recentlySearched.length > 0 && (
            <RecentSearches
              recentlySearched={props.recentlySearched}
              recentlySearchedTitle={props.recentlySearchedTitle}
              recentlySearchedText={props.recentlySearchedText}
              onClick={removeRecentlySearched}
              clearRecentSearch={props.clearRecentSearch}
              {...(!props.enablePromotionalSearch &&
                !props.trendingTerms && {
                  movePastInput: props.movePastInput,
                })}
              removeIndividualSearch={(e) => {
                removeIndividualSearch(e, props.setRecentSearches)
              }}
            />
          )}
        <SearchComponentsDropdown
          promotionalProducts={props.promotionalProducts}
          enablePromotionalSearch={props.enablePromotionalSearch}
          trendingTerms={props.trendingTerms}
          movePastInput={props.movePastInput}
          onClickSearchComponent={props.onClickSearchComponent}
          enableTrendingSearch={props.enableTrendingSearch}
        />
      </DropdownWrapper>
    </StyledSearchDropdown>
  )
}

const TRANSITION_TIME = 0.3
const SearchDropdown = (
  props: HeaderSearchFormProps & {
    instantSearchResults: SearchResults | null
    movePastInput: () => void
    clearInput: () => void
    clickAway: () => void
    inputValue: string
    correctionsIndex: number
    suggestionsIndex: number
    productsIndex: number
    inputFocused: boolean
    onClickSearchSuggestions?: (value: string) => void
    setRecentSearches?: (string) => void
    enablePromotionalSearch?: boolean
    promotionalProducts?: PromotionalProducts
    enableTrendingSearch?: boolean
    displaySocialEngagement?: boolean
  },
) => {
  const [
    lastValidSearchResults,
    setLastValidSearchResults,
  ] = React.useState<SearchResults | null>(null)

  React.useEffect(() => {
    setLastValidSearchResults(props.instantSearchResults)
  }, [props.instantSearchResults])

  const showSearchComponents = showSearchComponentsDropdown(
    props.enableTrendingSearch,
    props.enablePromotionalSearch,
    props.enableRecentSearches,
    props.inputFocused,
    props.inputValue,
    props.displaySocialEngagement,
  )

  return (
    <CustomAnimatePresence>
      <React.Fragment>
        {lastValidSearchResults && !showSearchComponents && (
          <StyledSearchResultsDropdown
            shouldDisplay={Boolean(
              props.inputValue &&
                props.instantSearchResults &&
                props.inputFocused,
            )}
            isDesktop={props.isDesktop}
            searchResults={lastValidSearchResults}
            searchI18nText={props.searchI18nText}
            autocompleteLink={props.autocompleteLink}
            inputValue={props.inputValue}
            correctionsIndex={props.correctionsIndex}
            suggestionsIndex={props.suggestionsIndex}
            productsIndex={props.productsIndex}
            clearInput={props.clearInput}
            movePastInput={props.movePastInput}
            fromText={props.searchI18nText.fromText}
            rrpText={props.searchI18nText.rrpText}
            freeText={props.searchI18nText.freeText}
            mobileSearch={props.mobileSearch}
            slimHeader={props.slimHeader}
            showPowerReview={props.showPowerReview}
            onClickSearchSuggestions={
              props.onClickSearchSuggestions && props.onClickSearchSuggestions
            }
          />
        )}
      </React.Fragment>
      {showSearchComponents && (
        <FocusedDropdownWithNoInput
          inputFocused={props.inputFocused}
          inputValue={props.inputValue}
          isDesktop={props.isDesktop}
          slimHeader={props.slimHeader}
          movePastInput={props.movePastInput}
          recentlySearched={props.recentlySearched}
          recentlySearchedTitle={props.recentlySearchedTitle}
          recentlySearchedText={props.recentlySearchedText}
          clearRecentSearch={props.clearRecentSearch}
          enablePromotionalSearch={props.enablePromotionalSearch}
          trendingTerms={props.trendingTerms}
          setRecentSearches={props.setRecentSearches}
          promotionalProducts={props.promotionalProducts}
          onClickSearchComponent={props.onClickSearchComponent}
          enableRecentSearches={props.enableRecentSearches}
          enableTrendingSearch={props.enableTrendingSearch}
        />
      )}
      {props.isDesktop && props.inputFocused && (
        <DropdownOverlay
          onClick={props.clickAway}
          onTouchStart={props.clickAway}
          key="search-overlay"
          data-testid="search-overlay"
          variants={{
            enter: { opacity: 0 },
            center: { opacity: 1 },
            exit: { opacity: 0 },
          }}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{ ease: 'easeInOut', duration: TRANSITION_TIME }}
        />
      )}
    </CustomAnimatePresence>
  )
}
